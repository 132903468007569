import * as React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import clsx from 'clsx';

import { currency } from 'lib/currency';
import { FilterBox } from 'components/PrivateMarketplace/TourList/FilterBox';
import { ProductCard } from 'components/PrivateMarketplace/ProductCard/ProductCard';
import { ProductSummaryShape, toProductSummaryShape } from 'lib/util/productSummaryShape';
import {
  selectCategories,
  selectFeatures,
  selectShowAvailabilitySearch,
} from 'lib/util/privateMarketplace';
import { LabelType } from 'lib/themes/themes';
import { getThemeCss } from 'components/Themes';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { getThemeCssOverride } from 'components/Themes';
import styles from './TourList.module.css';
import { appendQueryString } from 'lib/util/appendQueryString';
import { useQueryString } from 'hooks/useQueryString';
import { AvailabilityFilter } from './AvailabilityFilter';
import { fetchAvailableProductSummaries } from 'ducks/client/availableProductSummaries';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ReduxState } from 'ducks';

type SortType = 'DEFAULT' | 'PRODUCT_NAME_ASC' | 'PRODUCT_NAME_DESC' | 'PRICE_ASC' | 'PRICE_DESC';

interface Props {
  products: ProductSummaryShape[];
  filterTypes: ('CATEGORY' | 'FEATURE' | 'TAG')[];
  displayLabelTypes: LabelType[];
  sortDisabled?: boolean;
}

const ProductPageSize = 12;

export const TourList = ({ displayLabelTypes, filterTypes, products, sortDisabled }: Props) => {
  const { t, i18n } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);
  const dispatch = useDispatch();

  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const showAvailabilitySearch = useSelector(selectShowAvailabilitySearch);

  const [shouldUseAvailableProducts, setShouldUseAvailableProducts] = React.useState(false);
  const availableProducts = useSelector((state: ReduxState) =>
    state.availableProductSummaries.all.map(toProductSummaryShape)
  );
  const [initialDate, setInitialDate] = React.useState('');
  const [initialGuestCount, setInitialGuestCount] = React.useState<Record<string, number> | null>(
    null
  );

  const [shownProductsCount, setShownProductsCount] = React.useState<number>(ProductPageSize);
  const [sortBy, setSortBy] = React.useState<SortType | ''>('DEFAULT');

  const [categoryFilters, setCategoryFilters] = React.useState<string[]>([]);
  const categories = useSelector(selectCategories);
  const categoryCandidates = categories.map((c) => ({
    text: c.display_name || c.name,
    value: c.name,
  }));

  const [featureFilters, setFeatureFilters] = React.useState<string[]>([]);
  const features = useSelector(selectFeatures);
  const featureCandidates = features.map((f) => ({
    text: f.display_name || f.name,
    value: f.name,
  }));

  const [tagFilters, setTagFilters] = React.useState<string[]>([]);
  const tagCandidates = uniq(flatten(products.map((p) => p.tags))).map((t) => ({
    text: t,
    value: t,
  }));

  const [mobileFilterIsOpen, setMobileFilterIsOpen] = React.useState<boolean>(false);

  const productListEnclosingDivRef = React.useRef<HTMLDivElement>(null);
  const didMountRef = React.useRef(false);

  React.useEffect(() => {
    // When filters or sort by change, reset the shown products count.
    setShownProductsCount(ProductPageSize);
  }, [categoryFilters, featureFilters, tagFilters, sortBy]);
  React.useEffect(() => {
    if (didMountRef.current) {
      if (productListEnclosingDivRef.current) {
        productListEnclosingDivRef.current.scrollIntoView();
      }
    } else {
      didMountRef.current = true;
    }
  }, [categoryFilters, featureFilters, tagFilters]);

  let filteredProducts = [
    ...(shouldUseAvailableProducts
      ? products.filter((p) =>
          availableProducts.some((availableProduct) => availableProduct.id === p.id)
        )
      : products),
  ];
  if (categoryFilters.length > 0) {
    const productIds = flatten(
      categoryFilters.map(
        (categoryFilter) => categories.find((c) => c.name === categoryFilter)?.product_ids || []
      )
    );
    filteredProducts = filteredProducts.filter((p) => productIds.includes(p.id));
  }
  if (featureFilters.length > 0) {
    const productIds = flatten(
      featureFilters.map(
        (featureFilter) => features.find((f) => f.name === featureFilter)?.product_ids || []
      )
    );
    filteredProducts = filteredProducts.filter((p) => productIds.includes(p.id));
  }
  if (tagFilters.length > 0) {
    filteredProducts = filteredProducts.filter((p) =>
      tagFilters.every((tag) => p.tags?.includes(tag))
    );
  }

  if (!sortDisabled) {
    if (sortBy === 'PRODUCT_NAME_ASC') {
      filteredProducts.sort((product1, product2) =>
        product1.name.localeCompare(product2.name, 'en')
      );
    } else if (sortBy === 'PRODUCT_NAME_DESC') {
      filteredProducts.sort(
        (product1, product2) => -product1.name.localeCompare(product2.name, 'en')
      );
    } else if (sortBy === 'PRICE_ASC') {
      filteredProducts.sort((product1, product2) => {
        // Empty prices are always ordered after actual prices
        if (!product1.lowPrice && !product2.lowPrice) {
          return 0;
        }
        if (!product1.lowPrice) {
          return 1;
        }
        if (!product2.lowPrice) {
          return -1;
        }

        return currency(product1.lowPrice).value - currency(product2.lowPrice).value;
      });
    } else if (sortBy === 'PRICE_DESC') {
      filteredProducts.sort((product1, product2) => {
        // Empty prices are always ordered after actual prices
        if (!product1.lowPrice && !product2.lowPrice) {
          return 0;
        }
        if (!product1.lowPrice) {
          return 1;
        }
        if (!product2.lowPrice) {
          return -1;
        }

        return currency(product2.lowPrice).value - currency(product1.lowPrice).value;
      });
    }
  }

  const productsToShow =
    filteredProducts.length > shownProductsCount
      ? filteredProducts.slice(0, shownProductsCount)
      : [...filteredProducts];

  const sortOptions = [
    {
      value: 'DEFAULT',
      text: t('Standard'),
    },
    {
      value: 'PRODUCT_NAME_ASC',
      text: t('Name (A to Z)'),
    },
    {
      value: 'PRODUCT_NAME_DESC',
      text: t('Name (Z to A)'),
    },
    {
      value: 'PRICE_ASC',
      text: t('Price (low to high)'),
    },
    {
      value: 'PRICE_DESC',
      text: t('Price (high to low)'),
    },
  ];
  const handleSortByChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setSortBy(event.target.value as SortType);
  const handleShowMoreProductsClick = () => {
    setShownProductsCount(shownProductsCount + ProductPageSize);
  };

  const q = useQueryString();
  const params = new URLSearchParams(q);
  if (initialDate) {
    params.set('dt', initialDate);
  }
  if (initialGuestCount) {
    params.set('gc', JSON.stringify(initialGuestCount));
  }
  const queryString = params.toString();

  return (
    <section
      className={clsx(
        styles['base-sectionItem'],
        getThemeCssOverride(bookingWidgetTheme, 'base-sectionItem')
      )}
    >
      <div
        className={clsx({
          [styles['base-sectionItem__inner']]: true,
          [styles['nofilter']]: filterTypes.length === 0,
        })}
      >
        <input
          checked={mobileFilterIsOpen}
          onChange={() => setMobileFilterIsOpen(!mobileFilterIsOpen)}
          className={styles['base-sectionItem__inner__filter__flg']}
          type="checkbox"
          id="filter"
        />
        <label
          className={clsx(styles['base-sectionItem__inner__filter'], 'maincolor-bg')}
          htmlFor="filter"
        >
          <picture>
            <img src="/static/images/ic_search.svg" alt="search" />
          </picture>
        </label>
        <label className={styles['base-sectionItem__inner__filter__bg']} htmlFor="filter" />

        {filterTypes.length > 0 && (
          <div className={styles['base-sectionItem__inner__side']}>
            <div className={styles['c-card-filter']}>
              <h2 className={clsx(styles['c-card-filter__headline'], 'maincolor-bg')}>
                {t('Filter')}
              </h2>
              {showAvailabilitySearch && (
                <AvailabilityFilter
                  onClear={() => {
                    setInitialDate('');
                    setInitialGuestCount(null);
                    setShouldUseAvailableProducts(false);
                  }}
                  onFilter={async (
                    date: string,
                    adultCount: number,
                    childCount: number,
                    infantCount: number
                  ) => {
                    await dispatch(
                      fetchAvailableProductSummaries(
                        apiKey,
                        i18n.language,
                        date,
                        adultCount,
                        childCount,
                        infantCount
                      )
                    );
                    setInitialDate(date);
                    setInitialGuestCount({
                      Adult: adultCount,
                      Child: childCount,
                      Infant: infantCount,
                    });
                    setShouldUseAvailableProducts(true);
                  }}
                />
              )}
              {filterTypes.includes('CATEGORY') && categoryCandidates.length > 0 && (
                <FilterBox
                  id="filter-box"
                  title={t('Categories')}
                  filtered={categoryFilters}
                  onFilteredChange={setCategoryFilters}
                  candidates={categoryCandidates}
                />
              )}
              {filterTypes.includes('FEATURE') && featureCandidates.length > 0 && (
                <FilterBox
                  id="filter-box"
                  title={t('Featured')}
                  filtered={featureFilters}
                  onFilteredChange={setFeatureFilters}
                  candidates={featureCandidates}
                />
              )}
              {filterTypes.includes('TAG') && tagCandidates.length > 0 && (
                <FilterBox
                  id="filter-box"
                  title={t('Tag')}
                  filtered={tagFilters}
                  onFilteredChange={setTagFilters}
                  candidates={tagCandidates}
                />
              )}
              <a
                className={clsx(styles['c-card-filter__btn'], 'maincolor-bg')}
                onClick={() => setMobileFilterIsOpen(false)}
              >
                {t('Done')}
              </a>
            </div>
          </div>
        )}
        <div ref={productListEnclosingDivRef} className={styles['base-sectionItem__inner__main']}>
          <div className={styles['base-sectionItem__inner__main__header']}>
            <div className="base-headline">
              <div className="maincolor-ic">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                  <rect width="32" height="32" fill="none" />
                  <g>
                    <path
                      d="M7.5,24.5V10a1,1,0,0,0-1-1h-1a1,1,0,0,0-1,1V27.5A1.5,1.5,0,0,0,6,29H21.5a1,1,0,0,0,1-1V27a1,1,0,0,0-1-1H9A1.5,1.5,0,0,1,7.5,24.5Z"
                      fill="#0094cc"
                    />
                    <path
                      d="M21.5,4h-11a1,1,0,0,0-1,1V23a1,1,0,0,0,1,1h16a1,1,0,0,0,1-1V10Zm-1,7V6l5,5Z"
                      fill="#0094cc"
                    />
                  </g>
                </svg>
              </div>
              <h2 className={clsx('base-key__text', getThemeCss(bookingWidgetTheme, 'base-font'))}>
                {t('Products')}
              </h2>
            </div>
            {!sortDisabled && (
              <div className={styles['base-sectionItem__inner__main__header__select']}>
                <p className="maincolor-txt">{t('Sort:')}</p>
                <label>
                  <select name="sort" value={sortBy} onChange={handleSortByChange}>
                    {sortOptions.map((sortOption) => (
                      <option key={sortOption.value} value={sortOption.value}>
                        {sortOption.text}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            )}
          </div>
          <div id="product-list-pane" className={styles['base-sectionItem__inner__main__body']}>
            {productsToShow.length == 0 && (
              <p style={{ color: 'red' }}>{t('Sorry, no products were found.')}</p>
            )}
            <ul className={styles['base-sectionItem__inner__main__body__list']}>
              {productsToShow.map((product) => (
                <li
                  key={product.id}
                  className={styles['base-sectionItem__inner__main__body__list__item']}
                >
                  <Link href={appendQueryString(`/top/products/${product.id}`, queryString)}>
                    <ProductCard displayLabelTypes={displayLabelTypes} product={product} />
                  </Link>
                </li>
              ))}
            </ul>
            {filteredProducts.length > shownProductsCount && (
              <a
                onClick={handleShowMoreProductsClick}
                className={clsx(styles['base-sectionItem__inner__main__body__btn'], 'maincolor-bg')}
              >
                {t('See More')}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
