import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './TourList.module.css';

type Props = {
  id?: string;
  title: string;
  filtered: string[];
  onFilteredChange: (newFiltered: string[]) => void;
  candidates: {
    text: string;
    value: string;
  }[];
};

export const FilterBox = ({ candidates, filtered, id, onFilteredChange, title }: Props) => {
  const { t } = useTranslation();

  const [viewMore, setViewMore] = React.useState<boolean>(false);

  const showViewMoreButton = candidates.length >= 6;

  return (
    <div className={styles['c-card-filter__box']}>
      <p className={styles['c-card-filter__box__ttl']}>{title}</p>
      <ul
        className={clsx({
          [styles['c-card-filter__box__list']]: true,
          [styles['is-close']]: showViewMoreButton && !viewMore,
        })}
      >
        {candidates.map((candidate, idx) => (
          <li key={idx} className={styles['c-card-filter__box__list__item']}>
            <label>
              <input
                id={id}
                type="checkbox"
                name="cate"
                checked={filtered.includes(candidate.value)}
                onChange={() => {
                  const newFiltered = filtered.includes(candidate.value)
                    ? filtered.filter((f) => f !== candidate.value)
                    : [...filtered, candidate.value];

                  onFilteredChange(newFiltered);
                }}
              />
              <div className="maincolor-border">
                <span className="maincolor-bg" />
              </div>
              <p>{candidate.text}</p>
            </label>
          </li>
        ))}
      </ul>
      {showViewMoreButton && (
        <div
          className={clsx({
            [styles['c-card-filter__box__more']]: true,
            [styles['is-close']]: !viewMore,
            [styles['is-open']]: viewMore,
          })}
        >
          <a
            onClick={() => setViewMore(!viewMore)}
            className={clsx(
              styles['c-card-filter__box__more__btn'],
              'maincolor-txt',
              'maincolor-border'
            )}
          >
            {!viewMore ? t('Show More') : t('Show Less')}
          </a>
        </div>
      )}
    </div>
  );
};
