import * as React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import range from 'lodash/range';
import { ReduxState } from 'ducks';
import { useSelector } from 'react-redux';

import styles from './TourList.module.css';

interface Props {
  onFilter: (date: string, adultCount: number, childCount: number, infantCount: number) => void;
  onClear: () => void;
}

export const AvailabilityFilter = ({ onFilter, onClear }: Props) => {
  const [date, setDate] = React.useState('');
  const [adultCount, setAdultCount] = React.useState(0);
  const [childCount, setChildCount] = React.useState(0);
  const [infantCount, setInfantCount] = React.useState(0);

  const loading = useSelector((state: ReduxState) => state.availableProductSummaries.loading);

  const { t } = useTranslation();

  return (
    <div className={styles['c-card-filter__box']}>
      <p className="widget-calendar__title" style={{ fontSize: '16px' }}>
        {t('Date')}
      </p>
      <div className="widget-calendar__time">
        <label className={styles['form-calendar']}>
          <img src="/static/images/ic_calendar.svg" />
          <input
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
            type="date"
          />
        </label>
      </div>
      <p className="widget-calendar__title" style={{ fontSize: '16px' }}>
        {t('Participants')}
      </p>
      <label className="widget-calendar-pax-select">
        <p className="widget-calendar-selectTitle" style={{ fontSize: '14px' }}>
          {t('Adult')}
        </p>
        <p className="widget-calendar-select__num">{adultCount}</p>
        <select
          onChange={(e) => {
            setAdultCount(parseInt(e.target.value));
          }}
          value={adultCount}
          className="widget-calendar-select__item"
        >
          {range(0, 100).map((c) => (
            <option value={c} key={c}>
              {c}
            </option>
          ))}
        </select>
      </label>
      <Box mt={1}>
        <label className="widget-calendar-pax-select">
          <p className="widget-calendar-selectTitle" style={{ fontSize: '14px' }}>
            {t('Child')}
          </p>
          <p className="widget-calendar-select__num">{childCount}</p>
          <select
            onChange={(e) => {
              setChildCount(parseInt(e.target.value));
            }}
            value={childCount}
            className="widget-calendar-select__item"
          >
            {range(0, 100).map((c) => (
              <option value={c} key={c}>
                {c}
              </option>
            ))}
          </select>
        </label>
      </Box>
      <Box mt={1}>
        <label className="widget-calendar-pax-select">
          <p className="widget-calendar-selectTitle" style={{ fontSize: '14px' }}>
            {t('Infant')}
          </p>
          <p className="widget-calendar-select__num">{infantCount}</p>
          <select
            onChange={(e) => {
              setInfantCount(parseInt(e.target.value));
            }}
            value={infantCount}
            className="widget-calendar-select__item"
          >
            {range(0, 100).map((c) => (
              <option value={c} key={c}>
                {c}
              </option>
            ))}
          </select>
        </label>
      </Box>
      <Box display="flex" mt={4} mb={2}>
        <Box ml="auto" display="flex">
          <Button
            size="small"
            variant="contained"
            color="gray"
            onClick={() => {
              setDate('');
              setAdultCount(0);
              setChildCount(0);
              setInfantCount(0);
              onClear();
            }}
          >
            {t('Clear')}
          </Button>
          <Box ml={2}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => {
                if (date && !loading) {
                  onFilter(date, adultCount, childCount, infantCount);
                }
              }}
            >
              {loading ? <CircularProgress size={20} /> : t('Apply')}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
